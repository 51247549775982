// import "classlist-polyfill";//兼容处理

// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'


import Vue from 'vue'

import App from './App.vue'


// ant-design-vue
// import Antd from 'ant-design-vue';
// // import { Button } from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';
// // antd 框架
// Vue.use(Antd);
// // Vue.use(Button);


import { ConfigProvider,Layout,Menu,Icon,Button,Dropdown,Space,Tooltip,Modal,message,notification,Drawer,Tabs,Breadcrumb,Input,Divider,Popconfirm,Card,InputNumber,Alert,Collapse,Row,Col,DatePicker,Switch,Radio,FormModel,Table,Tag,Progress,Checkbox,Slider,TreeSelect,Upload,List } from 'ant-design-vue';

Vue.use(ConfigProvider);
Vue.use(Icon);
Vue.use(Button);
Vue.use(Dropdown);
Vue.use(Space);
Vue.use(Tooltip);
Vue.use(Modal);
Vue.use(Drawer);
Vue.use(Tabs);
Vue.use(Breadcrumb);
Vue.use(Input);
Vue.use(Divider);
Vue.use(Popconfirm);
Vue.use(Card);
Vue.use(InputNumber);
Vue.use(Alert);
Vue.use(Collapse);
Vue.use(Row);
Vue.use(Col);
Vue.use(DatePicker);
Vue.use(Switch);
Vue.use(Radio.Group);
Vue.use(Radio);
Vue.use(FormModel);
Vue.use(Table);
Vue.use(Tag);
Vue.use(Progress);
Vue.use(Checkbox);
Vue.use(Slider);
Vue.use(TreeSelect);
Vue.use(Upload);
Vue.use(Upload.Dragger);
Vue.use(List);
//Layout
// Vue.component(Layout.name, Layout);
// Vue.component(Layout.Header.name, Layout.Header);
// Vue.component(Layout.Content.name, Layout.Content);
// Vue.component(Layout.Footer.name, Layout.Footer);
// Vue.component(Layout.Sider.name, Layout.Sider);

Vue.use(Layout);

//Menu
// Vue.component(Menu.name, Menu);
// Vue.component(Menu.SubMenu.name, Menu.SubMenu);
// Vue.component(Menu.Item.name, Menu.Item);

Vue.use(Menu);


//注入方法
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning




import VueResource from 'vue-resource'
Vue.use(VueResource)



// 动画 4.1.1基础上改动过
import animated from './assets/animate.css';
Vue.use(animated);
// 滚动条
import "perfect-scrollbar/css/perfect-scrollbar.css";

//颜色
import vcolorpicker from 'vcolorpicker'
Vue.use(vcolorpicker)

Vue.config.productionTip = false
new Vue({
  render: h => h(App),
}).$mount('#app')
