export default {
    //浅层拷贝
    clone:function(obj){
        return JSON.parse(JSON.stringify(obj)); //对象内有 function 则不行
        // return Object.assign({}, obj); 对象内有对象则不行
    },
    //深拷贝
    cloneDeep:function (obj) { 
        if(obj === null) return null 
        if(typeof obj !== 'object') return obj;
        if(obj.constructor===Date) return new Date(obj); 
        if(obj.constructor === RegExp) return new RegExp(obj);
        var newObj = new obj.constructor ();  //保持继承链
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {   //不遍历其原型链上的属性
                var val = obj[key];
                newObj[key] = typeof val === 'object' ? arguments.callee(val) : val; // 使用arguments.callee解除与函数名的耦合
            }
        }  
        return newObj;  
    },
    isEmpty:function (obj) {
        return ('' == obj || 'null' == obj || null == obj || undefined == obj || "undefined" == obj) ? true : false;
    },
    randomString:function (len) {
    　　len = len || 32;
    　　var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';   
    　　var maxPos = chars.length;
    　　var pwd = '';
    　　for (var i = 0; i < len; i++) {
        　　pwd += chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
    },
}