export default {
    text: {
        name: 'text',
        title: '文本',
        content: '<p style="color:red">文本小部件内容，请在右边属性中修改</p>',
        animatePlay:{
            type:'in',
            name:'none',
            duration:1,//s
            delay:0,
            loop:1//0为无限
        },
        animate:{
            in:{
                type:'in',
                name:'random',
                duration:1,//s
                delay:0,
                loop:1
            },
            middle:{
                type:'middle',
                name:'none',
                duration:1,//s
                delay:0,
                loop:1
            },
            out:{
                type:'out',
                name:'random',
                duration:1,//s
                delay:0,
                loop:1
            },
            click:{
                type:'click',
                name:'none',
                duration:1,//s
                delay:0,
                loop:1
            }
        },
        clickAction:{
            action:"none", //none page link tel message 后期[video music]换背景音乐等
            page:{index:0},
            link:{
                link: '',//url
                path: '',//小程序页面
                appId: '' //小程序appId
            },
            message:'',
            tel:''
        },
        box:{
            x:10,
            y:270,
            width:300,
            height:'auto',
            zIndex:0,
            rotate: 0
        },
        draggable:{
            // rotatable:true,
            // draggable:true,
            // resizable:true,
            lockAspectRatio:false
        }
    },
    image: {
        name: 'image',
        title: '图片',
        // content: '<img src="https://www.2weima.com/static/images/qr-ad.jpg" style="max-width:100%;max-height:100%;"/>',
        src: '/static/images/h5editor/image-placeholder.jpg',
        attId:0,
        animatePlay:{
            type:'in',
            name:'none',
            duration:1,//s
            delay:0,
            loop:1
        },
        animate:{
            in:{
                type:'in',
                name:'random',
                duration:1,//s
                delay:0,
                loop:1
            },
            middle:{
                type:'middle',
                name:'none',
                duration:1,//s
                delay:0,
                loop:1
            },
            out:{
                type:'out',
                name:'random',
                duration:1,//s
                delay:0,
                loop:1
            },
            click:{
                type:'click',
                name:'none',
                duration:1,//s
                delay:0,
                loop:1
            }
        },
        clickAction:{
            action:"none", //none page link 后期[video music]
            page:{index:0},
            link:{
                link: '',//url
                path: '',//小程序页面
                appId: '' //小程序appId
            },
            message:'',
            tel:''
        },
        box:{
            x:10,
            y:100,
            width:300,
            height:300,
            zIndex:0,
            rotate: 0,
            opacity:100
        },
        draggable:{
            // rotatable:true,
            // draggable:true,
            // resizable:true,
            lockAspectRatio:false
        }
    }
}