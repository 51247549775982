export default [
    {
        title: "入场动画",
        type: "in",
        list: [
        {
            title:"无",
            icon:"stop",
            css:"none"
        },
        {
            title:"随机",
            icon:"ellipsis",
            css:"random"
        }
        ]
    },
    {
        title: "中场动画",
        type: "middle",
        list: [
        {
            title:"无",
            icon:"stop",
            css:"none"
        },
        {
            title:"随机",
            icon:"ellipsis",
            css:"random"
        }
        ]
    },
    {
        title: "出场动画",
        type: "out",
        list: [
        {
            title:"无",
            icon:"stop",
            css:"none"
        },
        {
            title:"随机",
            icon:"ellipsis",
            css:"random"
        }
        ]
    },
    {
        title: "点击动画",
        type: "click",
        list: [
        {
            title:"无",
            icon:"stop",
            css:"none"
        },
        {
            title:"随机",
            icon:"ellipsis",
            css:"random"
        }
        ]
    },
    {
        title: "高调",
        type: "middle",
        list: [
        {
            title:"跳动",
            icon:"to-top",
            css:"bounce"
        },
        {
            title:"警示",
            icon:"warning",
            css:"flash"
        },
        {
            title:"压力",
            icon:"vertical-align-middle",
            css:"rubberBand"
        },
        {
            title:"左右",
            icon:"swap",
            css:"shakeX"
        },
        {
            title:"上下",
            icon:"column-height",
            css:"shakeY"
        },
        {
            title:"摇摆",
            icon:"info",
            css:"swing"
        },
        {
            title:"弹簧",
            icon:"align-center",
            css:"tada"
        },
        {
            title:"摇晃",
            icon:"column-width",
            css:"wobble"
        },
        {
            title:"心跳",
            icon:"heart",
            css:"heartBeat"
        }
        ]
    },
    {
        title: "飞入",
        type: "in",
        list: [
        {
            title:"上升",
            icon:"up",
            css:"backInUp"
        },
        {
            title:"下落",
            icon:"down",
            css:"backInDown"
        },
        {
            title:"左入",
            icon:"right",
            css:"backInLeft"
        },
        {
            title:"右入",
            icon:"left",
            css:"backInRight"
        }
        ]
    },
    {
        title: "弹性飞入",
        type: "in",
        list: [
        {
            title:"上升",
            icon:"up",
            css:"bounceInUp"
        },
        {
            title:"下落",
            icon:"down",
            css:"bounceInDown"
        },
        {
            title:"左入",
            icon:"right",
            css:"bounceInLeft"
        },
        {
            title:"右入",
            icon:"left",
            css:"bounceInRight"
        }
        //  {
        //   title:"弹跳",
        //   css:"bounceIn"
        // }
        ]
    },
    {
        title: "低调渐显",
        type: "in",
        list: [
        
        {
            title:"下落",
            icon:"down",
            css:"fadeInDown"
        },
        {
            title:"左入",
            icon:"right",
            css:"fadeInLeft"
        },
        {
            title:"左上",
            icon:"radius-upleft",
            css:"fadeInTopLeft"
        },
        {
            title:"右上",
            icon:"radius-upright",
            css:"fadeInTopRight"
        },
        {
            title:"上升",
            icon:"up",
            css:"fadeInUp"
        },
        {
            title:"右入",
            icon:"left",
            css:"fadeInRight"
        },
        {
            title:"左下",
            icon:"radius-bottomleft",
            css:"fadeInBottomLeft"
        },
        {
            title:"右下",
            icon:"radius-bottomright",
            css:"fadeInBottomRight"
        }
        // {
        //   title:"渐显",
        //   css:"fadeIn"
        // },
        ]
    },
    {
        title: "翻转",
        type: "in",
        list: [
        {
            title:"空翻",
            icon:"down",
            css:"flip"
        },
        {
            title:"上翻",
            icon:"up",
            css:"flipInX"
        },
        {
            title:"左翻",
            icon:"left",
            css:"flipInY"
        }
        ]
    },
    {
        title: "旋转",
        type: "in",
        list: [
        {
            title:"左下",
            icon:"right",
            css:"rotateInDownLeft"
        },
        {
            title:"右下",
            icon:"left",
            css:"rotateInDownRight"
        },
        {
            title:"左上",
            icon:"up",
            css:"rotateInUpLeft"
        },
        {
            title:"左上",
            icon:"up",
            css:"rotateInUpRight"
        },
        {
            title:"旋转",
            icon:"retweet",
            css:"rotateIn"
        },
        {
            title:"丢入",
            icon:"up",
            css:"rollIn"
        }
        ]
    },
    {
        title: "缩放",
        type: "in",
        list: [
        {
            title:"上滑",
            icon:"up",
            css:"zoomInUp"
        },
        {
            title:"下滑",
            icon:"down",
            css:"zoomInDown"
        },
        {
            title:"左入",
            icon:"right",
            css:"zoomInLeft"
        },
        {
            title:"右入",
            icon:"left",
            css:"zoomInRight"
        },
        {
            title:"放大",
            icon:"fullscreen",
            css:"zoomIn"
        }
        ]
    },
    {
        title: "弹性飞出",
        type: "out",
        list: [
        {
            title:"上升",
            icon:"up",
            css:"bounceOutUp"
        },
        {
            title:"下落",
            icon:"down",
            css:"bounceOutDown"
        },
        {
            title:"左出",
            icon:"left",
            css:"bounceOutLeft"
        },
        {
            title:"右出",
            icon:"right",
            css:"bounceOutRight"
        }
        // {
        //   title:"弹跳",
        //   css:"bounceOut"
        // }
        ]
    },
    {
        title: "低调渐退",
        type: "out",
        list: [
        
        {
            title:"下落",
            icon:"down",
            css:"fadeOutDown"
        },
        {
            title:"左出",
            icon:"left",
            css:"fadeOutLeft"
        },
        {
            title:"左上",
            icon:"radius-upleft",
            css:"fadeOutTopLeft"
        },
        {
            title:"右上",
            icon:"radius-upright",
            css:"fadeOutTopRight"
        },
        {
            title:"上升",
            icon:"up",
            css:"fadeOutUp"
        },
        {
            title:"右出",
            icon:"right",
            css:"fadeOutRight"
        },
        {
            title:"左下",
            icon:"radius-bottomleft",
            css:"fadeOutBottomLeft"
        },
        {
            title:"右下",
            icon:"radius-bottomright",
            css:"fadeOutBottomRight"
        }
        // {
        //   title:"渐显",
        //   css:"fadeIn"
        // },
        ]
    },
    {
        title: "翻转",
        type: "out",
        list: [
        {
            title:"上翻",
            icon:"up",
            css:"flipOutX"
        },
        {
            title:"左翻",
            icon:"left",
            css:"flipOutY"
        }
        ]
    },
    {
        title: "旋转",
        type: "out",
        list: [
        {
            title:"左下",
            icon:"right",
            css:"rotateOutDownLeft"
        },
        {
            title:"右下",
            icon:"left",
            css:"rotateOutDownRight"
        },
        {
            title:"左上",
            icon:"up",
            css:"rotateOutUpLeft"
        },
        {
            title:"左上",
            icon:"up",
            css:"rotateOutUpRight"
        },
        {
            title:"旋转",
            icon:"retweet",
            css:"rotateOut"
        },
        {
            title:"丢出",
            icon:"right",
            css:"rollOut"
        },
        {
            title:"跌落",
            icon:"down",
            css:"hinge"
        }
        ]
    },
    {
        title: "缩放",
        type: "out",
        list: [
        {
            title:"上滑",
            icon:"up",
            css:"zoomOutUp"
        },
        {
            title:"下滑",
            icon:"down",
            css:"zoomOutDown"
        },
        {
            title:"左出",
            icon:"left",
            css:"zoomOutLeft"
        },
        {
            title:"右出",
            icon:"right",
            css:"zoomOutRight"
        },
        {
            title:"缩小",
            icon:"fullscreen-exit",
            css:"zoomOut"
        }
        ]
    },
    ];