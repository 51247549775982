export default {
    isActive:false,
    check:{
      type:'',
      index:null
    },
    primaryColor:'#1681b6',//主色
    imageSuCaiType:{
      shangren:'商人',
      baoan:'保安',
      shangwu:'商务经理',
      tuozhan:'拓展经理(女)',
      yongyan:'用研经理',
      chanpin:'产品经理',
      shejishi:'设计师(女)',
      chengxuyuan:'程序员',
      hulianwangren:'互联网人',
      tuxing:'图形',
      touxiang:'人种头像',
      zhuangtai:'状态',
      beijing:'背景装饰',
      jieri:'节日素材'
    },
    renwu:{
      
      hulianwangren:[
        {
          title:'摆手-欢呼',
          url:'/static/images/h5editor/svg/renwu/240.svg'
        },
        {
          title:'奔跑-打招呼',
          url:'/static/images/h5editor/svg/renwu/241.svg'
        },
        {
          title:'跪姿-介绍',
          url:'/static/images/h5editor/svg/renwu/242.svg'
        },
        {
          title:'后仰-讲解',
          url:'/static/images/h5editor/svg/renwu/243.svg'
        },
        {
          title:'起身-打招呼',
          url:'/static/images/h5editor/svg/renwu/244.svg'
        },
        {
          title:'站立-叉腰',
          url:'/static/images/h5editor/svg/renwu/245.svg'
        },
        {
          title:'站立-打电话',
          url:'/static/images/h5editor/svg/renwu/246.svg'
        },
        {
          title:'站立-打招呼',
          url:'/static/images/h5editor/svg/renwu/247.svg'
        },
        {
          title:'站立-讲解',
          url:'/static/images/h5editor/svg/renwu/248.svg'
        },
        {
          title:'站立-看手机',
          url:'/static/images/h5editor/svg/renwu/249.svg'
        },
        {
          title:'站立-摸头',
          url:'/static/images/h5editor/svg/renwu/250.svg'
        },
        {
          title:'站立-双手高举',
          url:'/static/images/h5editor/svg/renwu/251.svg'
        },
        {
          title:'站立-双手交叉',
          url:'/static/images/h5editor/svg/renwu/252.svg'
        },
        {
          title:'站立-思考',
          url:'/static/images/h5editor/svg/renwu/253.svg'
        },
        {
          title:'站立-讨论',
          url:'/static/images/h5editor/svg/renwu/254.svg'
        },
        {
          title:'站立-自信',
          url:'/static/images/h5editor/svg/renwu/255.svg'
        },
        {
          title:'走路-看电脑',
          url:'/static/images/h5editor/svg/renwu/256.svg'
        },
        {
          title:'坐姿-打招呼',
          url:'/static/images/h5editor/svg/renwu/257.svg'
        },
        {
          title:'坐姿-讲解',
          url:'/static/images/h5editor/svg/renwu/258.svg'
        },
        {
          title:'坐姿-讨论',
          url:'/static/images/h5editor/svg/renwu/259.svg'
        },
        {
          title:'坐姿-专注工作',
          url:'/static/images/h5editor/svg/renwu/260.svg'
        }
      ],
      shejishi:[//设计师
        {
          title:'摆手-欢呼',
          url:'/static/images/h5editor/svg/renwu/180.svg'
        },
        {
          title:'奔跑-打招呼',
          url:'/static/images/h5editor/svg/renwu/181.svg'
        },
        {
          title:'跪姿-介绍',
          url:'/static/images/h5editor/svg/renwu/182.svg'
        },
        {
          title:'后仰-讲解',
          url:'/static/images/h5editor/svg/renwu/183.svg'
        },
        {
          title:'起身-打招呼',
          url:'/static/images/h5editor/svg/renwu/184.svg'
        },
        {
          title:'站立-叉腰',
          url:'/static/images/h5editor/svg/renwu/185.svg'
        },
        {
          title:'站立-打电话',
          url:'/static/images/h5editor/svg/renwu/186.svg'
        },
        {
          title:'站立-打招呼',
          url:'/static/images/h5editor/svg/renwu/187.svg'
        },
        {
          title:'站立-讲解',
          url:'/static/images/h5editor/svg/renwu/188.svg'
        },
        {
          title:'站立-看手机',
          url:'/static/images/h5editor/svg/renwu/189.svg'
        },
        {
          title:'站立-摸头',
          url:'/static/images/h5editor/svg/renwu/190.svg'
        },
        {
          title:'站立-双手高举',
          url:'/static/images/h5editor/svg/renwu/191.svg'
        },
        {
          title:'站立-双手交叉',
          url:'/static/images/h5editor/svg/renwu/192.svg'
        },
        {
          title:'站立-思考',
          url:'/static/images/h5editor/svg/renwu/193.svg'
        },
        {
          title:'站立-讨论',
          url:'/static/images/h5editor/svg/renwu/194.svg'
        },
        {
          title:'站立-自信',
          url:'/static/images/h5editor/svg/renwu/195.svg'
        },
        {
          title:'走路-看电脑',
          url:'/static/images/h5editor/svg/renwu/196.svg'
        },
        {
          title:'坐姿-打招呼',
          url:'/static/images/h5editor/svg/renwu/197.svg'
        },
        {
          title:'坐姿-讲解',
          url:'/static/images/h5editor/svg/renwu/198.svg'
        },
        {
          title:'坐姿-讨论',
          url:'/static/images/h5editor/svg/renwu/199.svg'
        },
        {
          title:'坐姿-专注工作',
          url:'/static/images/h5editor/svg/renwu/200.svg'
        }
      ],
      chengxuyuan:[
        {
          title:'摆手-欢呼',
          url:'/static/images/h5editor/svg/renwu/210.svg'
        },
        {
          title:'奔跑-打招呼',
          url:'/static/images/h5editor/svg/renwu/211.svg'
        },
        {
          title:'跪姿-介绍',
          url:'/static/images/h5editor/svg/renwu/212.svg'
        },
        {
          title:'后仰-讲解',
          url:'/static/images/h5editor/svg/renwu/213.svg'
        },
        {
          title:'起身-打招呼',
          url:'/static/images/h5editor/svg/renwu/214.svg'
        },
        {
          title:'站立-叉腰',
          url:'/static/images/h5editor/svg/renwu/215.svg'
        },
        {
          title:'站立-打电话',
          url:'/static/images/h5editor/svg/renwu/216.svg'
        },
        {
          title:'站立-打招呼',
          url:'/static/images/h5editor/svg/renwu/217.svg'
        },
        {
          title:'站立-讲解',
          url:'/static/images/h5editor/svg/renwu/218.svg'
        },
        {
          title:'站立-看手机',
          url:'/static/images/h5editor/svg/renwu/219.svg'
        },
        {
          title:'站立-摸后脑勺',
          url:'/static/images/h5editor/svg/renwu/220.svg'
        },
        {
          title:'站立-双手高举',
          url:'/static/images/h5editor/svg/renwu/221.svg'
        },
        {
          title:'站立-双手交叉',
          url:'/static/images/h5editor/svg/renwu/222.svg'
        },
        {
          title:'站立-思考',
          url:'/static/images/h5editor/svg/renwu/223.svg'
        },
        {
          title:'站立-讨论',
          url:'/static/images/h5editor/svg/renwu/224.svg'
        },
        {
          title:'站立-自信',
          url:'/static/images/h5editor/svg/renwu/225.svg'
        },
        {
          title:'走路-看电脑',
          url:'/static/images/h5editor/svg/renwu/226.svg'
        },
        {
          title:'坐姿-打招呼',
          url:'/static/images/h5editor/svg/renwu/227.svg'
        },
        {
          title:'坐姿-工作',
          url:'/static/images/h5editor/svg/renwu/228.svg'
        },
        {
          title:'坐姿-讲解',
          url:'/static/images/h5editor/svg/renwu/229.svg'
        },
        {
          title:'坐姿-讨论',
          url:'/static/images/h5editor/svg/renwu/230.svg'
        }
      ],
      chanpin:[ //产品经理
        {
          title:'摆手-欢呼',
          url:'/static/images/h5editor/svg/renwu/150.svg'
        },
        {
          title:'奔跑-打招呼',
          url:'/static/images/h5editor/svg/renwu/151.svg'
        },
        {
          title:'跪姿-介绍',
          url:'/static/images/h5editor/svg/renwu/152.svg'
        },
        {
          title:'后仰-讲解',
          url:'/static/images/h5editor/svg/renwu/153.svg'
        },
        {
          title:'起身-打招呼',
          url:'/static/images/h5editor/svg/renwu/154.svg'
        },
        {
          title:'站立-叉腰',
          url:'/static/images/h5editor/svg/renwu/155.svg'
        },
        {
          title:'站立-打电话',
          url:'/static/images/h5editor/svg/renwu/156.svg'
        },
        {
          title:'站立-打招呼',
          url:'/static/images/h5editor/svg/renwu/157.svg'
        },
        {
          title:'站立-讲解',
          url:'/static/images/h5editor/svg/renwu/158.svg'
        },
        {
          title:'站立-看手机',
          url:'/static/images/h5editor/svg/renwu/159.svg'
        },
        {
          title:'站立-摸头',
          url:'/static/images/h5editor/svg/renwu/160.svg'
        },
        {
          title:'站立-双手高举',
          url:'/static/images/h5editor/svg/renwu/161.svg'
        },
        {
          title:'站立-双手交叉',
          url:'/static/images/h5editor/svg/renwu/162.svg'
        },
        {
          title:'站立-思考',
          url:'/static/images/h5editor/svg/renwu/163.svg'
        },
        {
          title:'站立-讨论',
          url:'/static/images/h5editor/svg/renwu/164.svg'
        },
        {
          title:'站立-自信',
          url:'/static/images/h5editor/svg/renwu/165.svg'
        },
        {
          title:'走路-看电脑',
          url:'/static/images/h5editor/svg/renwu/166.svg'
        },
        {
          title:'坐姿-打招呼',
          url:'/static/images/h5editor/svg/renwu/167.svg'
        },
        {
          title:'坐姿-讲解',
          url:'/static/images/h5editor/svg/renwu/168.svg'
        },
        {
          title:'坐姿-讨论',
          url:'/static/images/h5editor/svg/renwu/169.svg'
        },
        {
          title:'坐姿-专注工作',
          url:'/static/images/h5editor/svg/renwu/170.svg'
        }
      ],
      shangwu:[//商务
        {
          title:'摆手-欢呼',
          url:'/static/images/h5editor/svg/renwu/60.svg'
        },
        {
          title:'奔跑-打招呼',
          url:'/static/images/h5editor/svg/renwu/61.svg'
        },
        {
          title:'跪姿-介绍',
          url:'/static/images/h5editor/svg/renwu/62.svg'
        },
        {
          title:'后仰-讲解',
          url:'/static/images/h5editor/svg/renwu/63.svg'
        },
        {
          title:'起身-打招呼',
          url:'/static/images/h5editor/svg/renwu/64.svg'
        },
        {
          title:'站立-叉腰',
          url:'/static/images/h5editor/svg/renwu/65.svg'
        },
        {
          title:'站立-打电话',
          url:'/static/images/h5editor/svg/renwu/66.svg'
        },
        {
          title:'站立-打招呼',
          url:'/static/images/h5editor/svg/renwu/67.svg'
        },
        {
          title:'站立-讲解',
          url:'/static/images/h5editor/svg/renwu/68.svg'
        },
        {
          title:'站立-看手机',
          url:'/static/images/h5editor/svg/renwu/69.svg'
        },
        {
          title:'站立-摸后脑勺',
          url:'/static/images/h5editor/svg/renwu/70.svg'
        },
        {
          title:'站立-双手高举',
          url:'/static/images/h5editor/svg/renwu/71.svg'
        },
        {
          title:'站立-双手交叉',
          url:'/static/images/h5editor/svg/renwu/72.svg'
        },
        {
          title:'站立-思考',
          url:'/static/images/h5editor/svg/renwu/73.svg'
        },
        {
          title:'站立-讨论',
          url:'/static/images/h5editor/svg/renwu/74.svg'
        },
        {
          title:'站立-自信',
          url:'/static/images/h5editor/svg/renwu/75.svg'
        },
        {
          title:'走路-看电脑',
          url:'/static/images/h5editor/svg/renwu/76.svg'
        },
        {
          title:'坐姿-打招呼',
          url:'/static/images/h5editor/svg/renwu/77.svg'
        },
        {
          title:'坐姿-工作',
          url:'/static/images/h5editor/svg/renwu/78.svg'
        },
        {
          title:'坐姿-讲解',
          url:'/static/images/h5editor/svg/renwu/79.svg'
        },
        {
          title:'坐姿-讨论',
          url:'/static/images/h5editor/svg/renwu/80.svg'
        }
      ],
      tuozhan:[//商务拓展
        {
          title:'摆手-欢呼',
          url:'/static/images/h5editor/svg/renwu/90.svg'
        },
        {
          title:'奔跑-打招呼',
          url:'/static/images/h5editor/svg/renwu/91.svg'
        },
        {
          title:'跪姿-介绍',
          url:'/static/images/h5editor/svg/renwu/92.svg'
        },
        {
          title:'后仰-讲解',
          url:'/static/images/h5editor/svg/renwu/93.svg'
        },
        {
          title:'起身-打招呼',
          url:'/static/images/h5editor/svg/renwu/94.svg'
        },
        {
          title:'站立-叉腰',
          url:'/static/images/h5editor/svg/renwu/95.svg'
        },
        {
          title:'站立-打电话',
          url:'/static/images/h5editor/svg/renwu/96.svg'
        },
        {
          title:'站立-打招呼',
          url:'/static/images/h5editor/svg/renwu/97.svg'
        },
        {
          title:'站立-讲解',
          url:'/static/images/h5editor/svg/renwu/98.svg'
        },
        {
          title:'站立-看手机',
          url:'/static/images/h5editor/svg/renwu/99.svg'
        },
        {
          title:'站立-摸头',
          url:'/static/images/h5editor/svg/renwu/100.svg'
        },
        {
          title:'站立-双手高举',
          url:'/static/images/h5editor/svg/renwu/101.svg'
        },
        {
          title:'站立-双手交叉',
          url:'/static/images/h5editor/svg/renwu/102.svg'
        },
        {
          title:'站立-思考',
          url:'/static/images/h5editor/svg/renwu/103.svg'
        },
        {
          title:'站立-讨论',
          url:'/static/images/h5editor/svg/renwu/104.svg'
        },
        {
          title:'站立-自信',
          url:'/static/images/h5editor/svg/renwu/105.svg'
        },
        {
          title:'走路-看电脑',
          url:'/static/images/h5editor/svg/renwu/106.svg'
        },
        {
          title:'坐姿-打招呼',
          url:'/static/images/h5editor/svg/renwu/107.svg'
        },
        {
          title:'坐姿-讲解',
          url:'/static/images/h5editor/svg/renwu/108.svg'
        },
        {
          title:'坐姿-讨论',
          url:'/static/images/h5editor/svg/renwu/109.svg'
        },
        {
          title:'坐姿-专注工作',
          url:'/static/images/h5editor/svg/renwu/110.svg'
        }
      ],
      yongyan:[
        {
          title:'摆手-欢呼',
          url:'/static/images/h5editor/svg/renwu/120.svg'
        },
        {
          title:'奔跑-打招呼',
          url:'/static/images/h5editor/svg/renwu/121.svg'
        },
        {
          title:'跪姿-介绍',
          url:'/static/images/h5editor/svg/renwu/122.svg'
        },
        {
          title:'后仰-讲解',
          url:'/static/images/h5editor/svg/renwu/123.svg'
        },
        {
          title:'起身-打招呼',
          url:'/static/images/h5editor/svg/renwu/124.svg'
        },
        {
          title:'站立-叉腰',
          url:'/static/images/h5editor/svg/renwu/125.svg'
        },
        {
          title:'站立-打电话',
          url:'/static/images/h5editor/svg/renwu/126.svg'
        },
        {
          title:'站立-打招呼',
          url:'/static/images/h5editor/svg/renwu/127.svg'
        },
        {
          title:'站立-讲解',
          url:'/static/images/h5editor/svg/renwu/128.svg'
        },
        {
          title:'站立-解说',
          url:'/static/images/h5editor/svg/renwu/129.svg'
        },
        {
          title:'站立-看手机',
          url:'/static/images/h5editor/svg/renwu/130.svg'
        },
        {
          title:'站立-摸后脑勺',
          url:'/static/images/h5editor/svg/renwu/131.svg'
        },
        {
          title:'站立-双手高举',
          url:'/static/images/h5editor/svg/renwu/132.svg'
        },
        {
          title:'站立-双手交叉',
          url:'/static/images/h5editor/svg/renwu/133.svg'
        },
        {
          title:'站立-思考',
          url:'/static/images/h5editor/svg/renwu/134.svg'
        },
        {
          title:'站立-自信',
          url:'/static/images/h5editor/svg/renwu/135.svg'
        },
        {
          title:'走路-看电脑',
          url:'/static/images/h5editor/svg/renwu/136.svg'
        },
        {
          title:'坐姿-打招呼',
          url:'/static/images/h5editor/svg/renwu/137.svg'
        },
        {
          title:'坐姿-讲解',
          url:'/static/images/h5editor/svg/renwu/138.svg'
        },
        {
          title:'坐姿-讨论',
          url:'/static/images/h5editor/svg/renwu/139.svg'
        },
        {
          title:'坐姿-专注工作',
          url:'/static/images/h5editor/svg/renwu/140.svg'
        }
      ],
      shangren:[
        {
          title:'摆手-欢呼',
          url:'/static/images/h5editor/svg/renwu/1.svg'
        },
        {
          title:'奔跑-打招呼',
          url:'/static/images/h5editor/svg/renwu/2.svg'
        },
        {
          title:'跪姿-介绍',
          url:'/static/images/h5editor/svg/renwu/3.svg'
        },
        {
          title:'后仰-讲解',
          url:'/static/images/h5editor/svg/renwu/4.svg'
        },
        {
          title:'起身-打招呼',
          url:'/static/images/h5editor/svg/renwu/5.svg'
        },
        {
          title:'站立-叉腰',
          url:'/static/images/h5editor/svg/renwu/6.svg'
        },
        {
          title:'站立-打电话',
          url:'/static/images/h5editor/svg/renwu/7.svg'
        },
        {
          title:'站立-打招呼',
          url:'/static/images/h5editor/svg/renwu/8.svg'
        },
        {
          title:'站立-讲解',
          url:'/static/images/h5editor/svg/renwu/9.svg'
        },
        {
          title:'站立-看手机',
          url:'/static/images/h5editor/svg/renwu/10.svg'
        },
        {
          title:'站立-摸后脑勺',
          url:'/static/images/h5editor/svg/renwu/11.svg'
        },
        {
          title:'站立-双手高举',
          url:'/static/images/h5editor/svg/renwu/12.svg'
        },
        {
          title:'站立-双手交叉',
          url:'/static/images/h5editor/svg/renwu/13.svg'
        },
        {
          title:'站立-思考',
          url:'/static/images/h5editor/svg/renwu/14.svg'
        },
        {
          title:'站立-讨论',
          url:'/static/images/h5editor/svg/renwu/15.svg'
        },
        {
          title:'站立-自信',
          url:'/static/images/h5editor/svg/renwu/16.svg'
        },
        {
          title:'走路-看电脑',
          url:'/static/images/h5editor/svg/renwu/17.svg'
        },
        {
          title:'坐姿-打招呼',
          url:'/static/images/h5editor/svg/renwu/18.svg'
        },
        {
          title:'坐姿-讲解',
          url:'/static/images/h5editor/svg/renwu/19.svg'
        },
        {
          title:'坐姿-讨论',
          url:'/static/images/h5editor/svg/renwu/20.svg'
        },
        {
          title:'坐姿-专注工作',
          url:'/static/images/h5editor/svg/renwu/21.svg'
        }
      ],
      baoan:[//保安
        {
          title:'摆手-欢呼',
          url:'/static/images/h5editor/svg/renwu/30.svg'
        },
        {
          title:'奔跑-打招呼',
          url:'/static/images/h5editor/svg/renwu/31.svg'
        },
        {
          title:'跪姿-介绍',
          url:'/static/images/h5editor/svg/renwu/32.svg'
        },
        {
          title:'后仰-讲解',
          url:'/static/images/h5editor/svg/renwu/33.svg'
        },
        {
          title:'起身-打招呼',
          url:'/static/images/h5editor/svg/renwu/34.svg'
        },
        {
          title:'站立-叉腰',
          url:'/static/images/h5editor/svg/renwu/35.svg'
        },
        {
          title:'站立-打电话',
          url:'/static/images/h5editor/svg/renwu/36.svg'
        },
        {
          title:'站立-打招呼',
          url:'/static/images/h5editor/svg/renwu/37.svg'
        },
        {
          title:'站立-讲解',
          url:'/static/images/h5editor/svg/renwu/38.svg'
        },
        {
          title:'站立-看手机',
          url:'/static/images/h5editor/svg/renwu/39.svg'
        },
        {
          title:'站立-摸后脑勺',
          url:'/static/images/h5editor/svg/renwu/40.svg'
        },
        {
          title:'站立-双手高举',
          url:'/static/images/h5editor/svg/renwu/41.svg'
        },
        {
          title:'站立-双手交叉',
          url:'/static/images/h5editor/svg/renwu/42.svg'
        },
        {
          title:'站立-思考',
          url:'/static/images/h5editor/svg/renwu/43.svg'
        },
        {
          title:'站立-讨论',
          url:'/static/images/h5editor/svg/renwu/44.svg'
        },
        {
          title:'站立-自信',
          url:'/static/images/h5editor/svg/renwu/45.svg'
        },
        {
          title:'走路-看电脑',
          url:'/static/images/h5editor/svg/renwu/46.svg'
        },
        {
          title:'坐姿-打招呼',
          url:'/static/images/h5editor/svg/renwu/47.svg'
        },
        {
          title:'坐姿-讲解',
          url:'/static/images/h5editor/svg/renwu/48.svg'
        },
        {
          title:'坐姿-讨论',
          url:'/static/images/h5editor/svg/renwu/49.svg'
        },
        {
          title:'坐姿-专注工作',
          url:'/static/images/h5editor/svg/renwu/50.svg'
        }
      ]


    },
    tubiao:{
      tuxing:[
        {
          title:'DNA',
          url:'/static/images/h5editor/svg/tuxing/1.svg'
        },
        {
          title:'DNA基因',
          url:'/static/images/h5editor/svg/tuxing/2.svg'
        },
        {
          title:'百分比符号',
          url:'/static/images/h5editor/svg/tuxing/3.svg'
        },
        {
          title:'贝塞尔曲线',
          url:'/static/images/h5editor/svg/tuxing/4.svg'
        },
        {
          title:'除于号',
          url:'/static/images/h5editor/svg/tuxing/5.svg'
        },
        {
          title:'粗边三角形',
          url:'/static/images/h5editor/svg/tuxing/6.svg'
        },
        {
          title:'粗边五边形',
          url:'/static/images/h5editor/svg/tuxing/7.svg'
        },
        {
          title:'粗边五角星',
          url:'/static/images/h5editor/svg/tuxing/8.svg'
        },
        {
          title:'粗边圆形',
          url:'/static/images/h5editor/svg/tuxing/9.svg'
        },
        {
          title:'粗边正方形',
          url:'/static/images/h5editor/svg/tuxing/10.svg'
        },
        {
          title:'代码符号02',
          url:'/static/images/h5editor/svg/tuxing/11.svg'
        },
        {
          title:'点',
          url:'/static/images/h5editor/svg/tuxing/12.svg'
        },
        {
          title:'点立法',
          url:'/static/images/h5editor/svg/tuxing/13.svg'
        },
        {
          title:'点线',
          url:'/static/images/h5editor/svg/tuxing/14.svg'
        },
        {
          title:'点线三角',
          url:'/static/images/h5editor/svg/tuxing/15.svg'
        },
        {
          title:'点线结构',
          url:'/static/images/h5editor/svg/tuxing/16.svg'
        },
        {
          title:'点线三角体',
          url:'/static/images/h5editor/svg/tuxing/17.svg'
        },
        {
          title:'对话框',
          url:'/static/images/h5editor/svg/tuxing/18.svg'
        },
        {
          title:'方块积木',
          url:'/static/images/h5editor/svg/tuxing/19.svg'
        },
        {
          title:'蜂窝',
          url:'/static/images/h5editor/svg/tuxing/20.svg'
        },
        {
          title:'蜂窝分子结构',
          url:'/static/images/h5editor/svg/tuxing/21.svg'
        },
        {
          title:'蜂窝结构',
          url:'/static/images/h5editor/svg/tuxing/22.svg'
        },
        {
          title:'关联',
          url:'/static/images/h5editor/svg/tuxing/23.svg'
        },
        {
          title:'加载符号',
          url:'/static/images/h5editor/svg/tuxing/24.svg'
        },
        {
          title:'箭头白色',
          url:'/static/images/h5editor/svg/tuxing/25.svg'
        },
        {
          title:'箭头深色',
          url:'/static/images/h5editor/svg/tuxing/26.svg'
        },
        {
          title:'矩阵点圆',
          url:'/static/images/h5editor/svg/tuxing/27.svg'
        },
        {
          title:'括号',
          url:'/static/images/h5editor/svg/tuxing/28.svg'
        },
        {
          title:'粒子',
          url:'/static/images/h5editor/svg/tuxing/29.svg'
        },
        {
          title:'菱形组合',
          url:'/static/images/h5editor/svg/tuxing/30.svg'
        },
        {
          title:'六边形点阵结构',
          url:'/static/images/h5editor/svg/tuxing/31.svg'
        },
        {
          title:'马赛克',
          url:'/static/images/h5editor/svg/tuxing/32.svg'
        },
        {
          title:'平行矩阵点',
          url:'/static/images/h5editor/svg/tuxing/33.svg'
        },
        {
          title:'求和符号',
          url:'/static/images/h5editor/svg/tuxing/34.svg'
        },
        {
          title:'三角构成',
          url:'/static/images/h5editor/svg/tuxing/35.svg'
        },
        {
          title:'三角节点',
          url:'/static/images/h5editor/svg/tuxing/36.svg'
        },
        {
          title:'三角形',
          url:'/static/images/h5editor/svg/tuxing/37.svg'
        },
        {
          title:'四角星',
          url:'/static/images/h5editor/svg/tuxing/38.svg'
        },
        {
          title:'五边形',
          url:'/static/images/h5editor/svg/tuxing/39.svg'
        },
        {
          title:'五角星收藏',
          url:'/static/images/h5editor/svg/tuxing/40.svg'
        },
        {
          title:'线条波纹',
          url:'/static/images/h5editor/svg/tuxing/41.svg'
        },
        {
          title:'线性钻石',
          url:'/static/images/h5editor/svg/tuxing/42.svg'
        },
        {
          title:'原子',
          url:'/static/images/h5editor/svg/tuxing/43.svg'
        },
        {
          title:'原子结构',
          url:'/static/images/h5editor/svg/tuxing/44.svg'
        },
        {
          title:'圆形',
          url:'/static/images/h5editor/svg/tuxing/45.svg'
        },
        {
          title:'圆柱结构',
          url:'/static/images/h5editor/svg/tuxing/46.svg'
        },
        {
          title:'圆柱与点',
          url:'/static/images/h5editor/svg/tuxing/47.svg'
        },
        {
          title:'正方形',
          url:'/static/images/h5editor/svg/tuxing/48.svg'
        },
        {
          title:'直线交叉',
          url:'/static/images/h5editor/svg/tuxing/49.svg'
        },
        {
          title:'锥形',
          url:'/static/images/h5editor/svg/tuxing/50.svg'
        }
      ],
      touxiang:[
        {
          title:'东南亚',
          url:'/static/images/h5editor/svg/touxiang/1.svg'
        },
        {
          title:'非洲',
          url:'/static/images/h5editor/svg/touxiang/2.svg'
        },
        {
          title:'拉丁美',
          url:'/static/images/h5editor/svg/touxiang/3.svg'
        },
        {
          title:'男白种人',
          url:'/static/images/h5editor/svg/touxiang/4.svg'
        },
        {
          title:'男东南亚',
          url:'/static/images/h5editor/svg/touxiang/5.svg'
        },
        {
          title:'男黄种人',
          url:'/static/images/h5editor/svg/touxiang/6.svg'
        },
        {
          title:'女白种人',
          url:'/static/images/h5editor/svg/touxiang/7.svg'
        },
        {
          title:'女非洲',
          url:'/static/images/h5editor/svg/touxiang/8.svg'
        },
        {
          title:'女黄种人',
          url:'/static/images/h5editor/svg/touxiang/9.svg'
        },
        {
          title:'女拉丁美',
          url:'/static/images/h5editor/svg/touxiang/10.svg'
        },
        {
          title:'圆头',
          url:'/static/images/h5editor/svg/touxiang/11.svg'
        },
        {
          title:'艾里',
          url:'/static/images/h5editor/svg/touxiang/12.svg'
        },
        {
          title:'凯撒',
          url:'/static/images/h5editor/svg/touxiang/13.svg'
        },
        {
          title:'琼戈',
          url:'/static/images/h5editor/svg/touxiang/14.svg'
        },
        {
          title:'卷发',
          url:'/static/images/h5editor/svg/touxiang/15.svg'
        },
        {
          title:'头巾',
          url:'/static/images/h5editor/svg/touxiang/16.svg'
        },
        {
          title:'头巾2',
          url:'/static/images/h5editor/svg/touxiang/17.svg'
        },
        {
          title:'长发',
          url:'/static/images/h5editor/svg/touxiang/18.svg'
        },
        {
          title:'光头',
          url:'/static/images/h5editor/svg/touxiang/19.svg'
        },
        {
          title:'马尾',
          url:'/static/images/h5editor/svg/touxiang/20.svg'
        },
        {
          title:'小苏',
          url:'/static/images/h5editor/svg/touxiang/21.svg'
        },
        {
          title:'小男',
          url:'/static/images/h5editor/svg/touxiang/22.svg'
        }
      ],
      zhuangtai:[
        {
          title:'扫描无数据',
          url:'/static/images/h5editor/svg/zhuangtai/1.svg'
        },
        {
          title:'设置成功',
          url:'/static/images/h5editor/svg/zhuangtai/2.svg'
        },
        {
          title:'文件为空',
          url:'/static/images/h5editor/svg/zhuangtai/3.svg'
        },
        {
          title:'快捷上传',
          url:'/static/images/h5editor/svg/zhuangtai/4.svg'
        },
        {
          title:'交流',
          url:'/static/images/h5editor/svg/zhuangtai/5.svg'
        },
        {
          title:'文件创建成功',
          url:'/static/images/h5editor/svg/zhuangtai/6.svg'
        },
        {
          title:'全球化',
          url:'/static/images/h5editor/svg/zhuangtai/7.svg'
        },
        {
          title:'搜索',
          url:'/static/images/h5editor/svg/zhuangtai/8.svg'
        },
        {
          title:'创意',
          url:'/static/images/h5editor/svg/zhuangtai/9.svg'
        },
        {
          title:'设置出错',
          url:'/static/images/h5editor/svg/zhuangtai/10.svg'
        },
        {
          title:'配置数据',
          url:'/static/images/h5editor/svg/zhuangtai/11.svg'
        },
        {
          title:'配置图标图表',
          url:'/static/images/h5editor/svg/zhuangtai/12.svg'
        },
        {
          title:'选择图表',
          url:'/static/images/h5editor/svg/zhuangtai/13.svg'
        },
        {
          title:'上传图片',
          url:'/static/images/h5editor/svg/zhuangtai/14.svg'
        }
      ],
      beijing:[
        {
          title:'开场秀',
          url:'/static/images/h5editor/h5bg.png'
        },
        {
          title:'家',
          url:'/static/images/h5editor/svg/beijing/4.svg'
        },
        {
          title:'植物',
          url:'/static/images/h5editor/svg/beijing/5.svg'
        },
        {
          title:'手绘白板',
          url:'/static/images/h5editor/svg/beijing/6.svg'
        },
        {
          title:'框架图',
          url:'/static/images/h5editor/svg/beijing/7.svg'
        },
        {
          title:'圆形',
          url:'/static/images/h5editor/svg/beijing/1.svg'
        },
        {
          title:'正方形',
          url:'/static/images/h5editor/svg/beijing/2.svg'
        },
        {
          title:'立方体',
          url:'/static/images/h5editor/svg/beijing/3.svg'
        }
      ]
    },
    cco:{
      jieri:[
        {
          title:'1个灯笼',
          url:'/static/images/h5editor/jieri/1.png'
        },
        {
          title:'3个灯笼',
          url:'/static/images/h5editor/jieri/2.png'
        },
        {
          title:'爱情鸟',
          url:'/static/images/h5editor/jieri/3.png'
        },
        {
          title:'灯笼背景',
          url:'/static/images/h5editor/jieri/4.jpg'
        },
        {
          title:'福炮',
          url:'/static/images/h5editor/jieri/5.png'
        },
        {
          title:'好奇猫',
          url:'/static/images/h5editor/jieri/6.png'
        },
        {
          title:'祭',
          url:'/static/images/h5editor/jieri/7.png'
        },
        {
          title:'结婚',
          url:'/static/images/h5editor/jieri/8.jpg'
        },
        {
          title:'礼物盒',
          url:'/static/images/h5editor/jieri/9.png'
        },
        {
          title:'猫猫',
          url:'/static/images/h5editor/jieri/10.png'
        },
        {
          title:'气球',
          url:'/static/images/h5editor/jieri/11.png'
        },
        {
          title:'情侣',
          url:'/static/images/h5editor/jieri/12.jpg'
        },
        {
          title:'生日蛋糕',
          url:'/static/images/h5editor/jieri/13.png'
        },
        {
          title:'心心',
          url:'/static/images/h5editor/jieri/14.png'
        },
        {
          title:'复古信纸',
          url:'/static/images/h5editor/jieri/15.png'
        },
        {
          title:'爱心信纸',
          url:'/static/images/h5editor/jieri/16.png'
        },
        {
          title:'雪景',
          url:'/static/images/h5editor/jieri/17.jpg'
        }
      ],
      beijing:[
        {
          title:'海边',
          url:'/static/images/h5editor/beijing/1.jpg'
        },
        {
          title:'湖景',
          url:'/static/images/h5editor/beijing/2.jpg'
        },
        {
          title:'路面',
          url:'/static/images/h5editor/beijing/3.jpg'
        },
        {
          title:'露水',
          url:'/static/images/h5editor/beijing/4.jpg'
        },
        {
          title:'桥',
          url:'/static/images/h5editor/beijing/5.jpg'
        },
        {
          title:'秋天',
          url:'/static/images/h5editor/beijing/6.jpg'
        },
        {
          title:'树尾',
          url:'/static/images/h5editor/beijing/7.jpg'
        },
        {
          title:'水珠',
          url:'/static/images/h5editor/beijing/8.jpg'
        },
        {
          title:'铁桥',
          url:'/static/images/h5editor/beijing/9.jpg'
        },
        {
          title:'小河',
          url:'/static/images/h5editor/beijing/10.jpg'
        },
        {
          title:'阳光',
          url:'/static/images/h5editor/beijing/11.jpg'
        },
        {
          title:'月亮',
          url:'/static/images/h5editor/beijing/12.jpg'
        }
      ]
    }

   
  };